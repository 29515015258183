import React, { useRef, useState } from 'react';
import classes from './Contact.module.css';
import { PrimaryButton } from './Buttons';
import { send } from 'emailjs-com';

const Contact = () => {

    const nameRef = useRef('');
    const emailRef = useRef('');
    const subjectRef = useRef('');
    const messageRef = useRef('');

    const buttonInitialText = "Send"
    const [buttonText, setButtonText] = useState(buttonInitialText);

    const SERVICE_ID = process.env.REACT_APP_SERVICE_ID
    const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID
    const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

    function onFormSubmit(e){
        e.preventDefault();
        var emailDetails = {
            from_name: nameRef.current.value,
            reply_to: emailRef.current.value,
            subject: subjectRef.current.value,
            message: messageRef.current.value
        }
        document.getElementById('sendEmailButton').disabled = true;
        setButtonText("Please Wait");
        
        send(
            SERVICE_ID,
            TEMPLATE_ID,
            emailDetails,
            EMAILJS_PUBLIC_KEY
        ).then((response) => {
            nameRef.current.value = ""
            emailRef.current.value = ""
            subjectRef.current.value = ""
            messageRef.current.value = ""
            setButtonText("Sent!");
            setTimeout(() => {
                setButtonText(buttonInitialText);
                document.getElementById('sendEmailButton').disabled = false;
            }, 1500);
        }).catch((error) => {
            window.alert("There was a problem in sending your message, please try again.")
            setButtonText(buttonInitialText);
            document.getElementById('sendEmailButton').disabled = false;
        })
    }

    return (
        <div className={classes['parent-div']}>
            <form onSubmit={onFormSubmit}>
                <div className={classes['name-email-div']}>
                    <input className={classes['input-field']} type="text" id={classes["name"]} name="from_name" placeholder='Name' required ref={nameRef} />
                    <input className={classes['input-field']} type="email" id={classes["email"]} name="reply_to" placeholder='Email' required ref={emailRef}/>
                </div>
                <input className={classes['input-field']} type="text" id={classes["subject"]} name="subject" placeholder='Subject' required ref={subjectRef}/>
                <textarea className={classes['input-field']} id={classes["message"]} name="message" rows={10} cols={20} placeholder='Message' required ref={messageRef}></textarea>
                <PrimaryButton id='sendEmailButton' className={classes['send-email-button']} type='submit' margintop={'16px'}>{buttonText}</PrimaryButton>
            </form>

        </div>
    )
}

export default Contact