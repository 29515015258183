import classes from './App.module.css';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';
import Headings from './Headings';
import Intro from './Intro';
import Projects from './Projects';

function App() {
  return (
    <div className={classes["main-div"]}>
      <Header/>
      <Intro/>
      <Headings heading={"PROJECTS"} id="Projects"/>
      <Projects/>
      <Headings heading={"GET IN TOUCH"} id="Contact"/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
