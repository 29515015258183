import React from 'react';
import classes from './Headings.module.css';

const Headers = (props) => {
    return (
        <div className={classes['parent-div']} id={props.id}>
            <span className={classes['horizontal-line']}/>
            <span className={classes['heading']}>{props.heading}</span>
            <span className={classes['horizontal-line']} />
        </div>
    )
}

export default Headers