import React, { useState } from 'react';
import classes from './Header.module.css'
import logo from './assets/I-logo.png';
import githubIcon from './assets/icon-github.svg';
import linkedinIcon from './assets/icon-linkedin.svg';
import frontendMIcon from './assets/icon-frontend.svg';
import menuIcon from './assets/icon-menu.svg';
import closeIcon from './assets/icon-close.svg';
import { PrimaryButton } from './Buttons';
import { BrowserRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import resume from './assets/Irine_B_Thayil_Resume.pdf';

const Header = () => {

    const [isNavOpen, setNavState] = useState(false);

    function onNavToggle() {
        setNavState(prevNavState => {
            return !prevNavState;
        })
    }

    return (
        <BrowserRouter>
            <div className={classes['parent-div']} id='Home' >
                <div className={classes['header-left-div']}>
                    <img id={classes['logo']} src={logo} title='Irine B Thayil' alt='Irine B Thayil' />
                    <div className={classes['header-icons-div']}>
                        <a href='https://github.com/irinebthayil' target='_blank'><img src={githubIcon} className={classes['header-icons']} title="Irine's Github" alt="Irine's Github" /></a>
                        <a href='https://www.linkedin.com/in/irinebthayil/' target='_blank'><img src={linkedinIcon} className={classes['header-icons']} title="Irine's LinkedIn" alt="Irine's LinkedIn" /></a>
                        <a href='https://www.frontendmentor.io/profile/irinebthayil' target='_blank'><img src={frontendMIcon} className={classes['header-icons']} title="Irine's Frontend Mentor" alt="Irine's Frontend Mentor" /></a>
                    </div>
                </div>
                <img id={classes['menuIcon']} src={menuIcon} onClick={onNavToggle} title='menu' alt='menu'/>
                <nav className={`${classes['header-nav-div']} ${!isNavOpen ? classes['hide'] : ''}`}>
                    <img id={classes['closeIcon']} src={closeIcon} onClick={onNavToggle} />
                    <HashLink smooth to='/#Home' className={classes['header-navlink']}>Home</HashLink>

                    <HashLink smooth to='/#Projects' className={classes['header-navlink']}>Projects</HashLink>

                    <HashLink smooth to='/#Contact' className={classes['header-navlink']}>Contact</HashLink>

                    <a href={resume} download="Irine_B_Thayil_Resume" target='_blank'><PrimaryButton id={classes['downloadButton']}>Download Resume</PrimaryButton></a>
                    
                </nav>
            </div>
        </BrowserRouter>
    )
}

export default Header