import React, { useEffect, useState } from 'react';
import classes from './Intro.module.css';
import { PrimaryButton } from './Buttons';
import reactIcon from './assets/icon-react.svg';
import htmlIcon from './assets/icon-html.svg';
import cssIcon from './assets/icon-css.svg';
import jsIcon from './assets/icon-js.svg';
import pythonIcon from './assets/icon-python.svg';
import mysqlIcon from './assets/icon-mysql.svg';
import androidIcon from './assets/icon-android.svg';
import phpIcon from './assets/icon-php.svg';
import javaIcon from './assets/icon-java.svg';
import { BrowserRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Intro = () => {

    const [experience, setExperience] = useState('2.9');
    useEffect(() => {
        var currentDate = new Date();

        var targetDate = new Date(2020, 9, 1);

        var yearsDiff = currentDate.getFullYear() - targetDate.getFullYear();
        var monthsDiff = currentDate.getMonth() - targetDate.getMonth();

        if (monthsDiff < 0) {
            yearsDiff--;
            monthsDiff += 12;
        }

        setExperience(`${yearsDiff}.${monthsDiff}`)
    }, []);

    return (
        <BrowserRouter>
            <div className={classes['parent-div']}>
                <div className={classes['intro-about-div']}>
                    <h1 id={classes['name']}>IRINE B THAYIL</h1>
                    <p id={classes['role']}>Full-stack developer</p>
                    <p id={classes['description']}>I am a <i>Software Development Engineer</i> with <i>{experience} years of experience</i> specializing in <i>Full-stack</i> and <i>Android development</i>. My goal is to build software that not only solves problems but also enriches people's lives.</p>
                    <HashLink smooth to='/#Contact'><PrimaryButton margintop={'16px'}>Contact Me</PrimaryButton></HashLink>
                </div>

                <div className={classes["intro-skills-div"]}>
                    <div className={classes["skills-inner-div"]}>
                        <div className={classes["icons-div"]} id={classes["circle"]}>
                            <div className={classes["skills-icons"]}>
                                <img src={reactIcon} alt="React" />
                                <p>React</p>
                            </div>
                            <div className={classes["skills-icons"]} deg='45deg'>
                                <img src={htmlIcon} alt="HTML" />
                                <p>HTML</p>
                            </div>
                            <div className={classes["skills-icons"]}>
                                <img src={cssIcon} alt="CSS" />
                                <p>CSS</p>
                            </div>
                            <div className={classes["skills-icons"]}>
                                <img src={jsIcon} alt="JavaScript" />
                                <p>JavaScript</p>
                            </div>
                            <div className={classes["skills-icons"]}>
                                <img src={javaIcon} alt="Java" />
                                <p>Java</p>
                            </div>
                            <div className={classes["skills-icons"]}>
                                <img src={phpIcon} alt="PHP" />
                                <p>PHP</p>
                            </div>
                            <div className={classes["skills-icons"]}>
                                <img src={pythonIcon} alt="Python" />
                                <p>Python</p>
                            </div>
                            <div className={classes["skills-icons"]}>
                                <img src={mysqlIcon} alt='mySQL' />
                                <p>MySQL</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BrowserRouter>

    )
}

export default Intro