import React from 'react';
import p1 from './assets/images/ss_dictionary.png'
import p2 from './assets/images/ss_todoapp.png'
import p3 from './assets/images/ss_ip_address_tracker.png'
import classes from './Projects.module.css'
import { SecondaryButton, PrimaryButton2 } from './Buttons';

import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Pagination } from 'swiper';

const Projects = () => {

    const projects = [
        {
            image: p1,
            heading: "Dictionary",
            desc: "Comprehensive Dictionary app using designs from Frontend Mentor with interactive search functionality, fetching word definitions from an API to provide users with quick and accurate results.",
            tools: "ReactJS | CSS | fetch | ContextAPI",
            github: "https://github.com/irinebthayil/dictionary",
            website: "http://irinebthayil.github.io/dictionary"
        },
        {
            image: p2,
            heading: "To Do App",
            desc: "Visually appealing and functional To-Do web app using designs from Frontend Mentor, enabling users to efficiently manage and filter their tasks and stay organized.",
            tools: "ReactJS | CSS | ContextAPI",
            github: "https://github.com/irinebthayil/todo-app",
            website: "http://irinebthayil.github.io/todo-app"
        },
        {
            image: p3,
            heading: "IP Address Tracker",
            desc: "IP Address Tracker web app utilizing designs from Frontend Mentor, fetching real-time geolocation data and IP details from an API, and integrating Mapbox for interactive mapping functionality",
            tools: "ReactJS | CSS | fetch | MapBox",
            github: "https://github.com/irinebthayil/ip-address-tracker",
            website: "http://irinebthayil.github.io/ip-address-tracker"
        }
    ];

    function openLink(url) {
        window.open(url, "_blank", "noreferrer");
    }

    return (
        <div className={classes['projects-parent-div']}>
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={1}
                spaceBetween={32}
                initialSlide={1}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5,
                    slideShadows: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination]}
                breakpoints={{
                    768:{
                        slidesPerView: 2
                    }
                }}
                className={classes["swiper"]}>
                {
                    projects.map((project, index) => (
                        <SwiperSlide key={index} className={classes['swiper-slide']}>
                            <img src={project.image} alt={project.heading} title={project.heading}/>
                            <p className={classes['project-name']}>{project.heading}</p>
                            <p className={classes['project-desc']}>{project.desc}</p>
                            <p className={classes['project-tools']}>{project.tools}</p>
                            <div className={classes['link-buttons-div']}>
                                <SecondaryButton onClick={() => openLink(project.github)}>Github</SecondaryButton>
                                <PrimaryButton2 onClick={() => openLink(project.website)}>Website</PrimaryButton2>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}

export default Projects