import React from 'react';
import classes from './Footer.module.css';
import logo from './assets/I-logo.png';
import githubIcon from './assets/icon-github.svg';
import linkedinIcon from './assets/icon-linkedin.svg';
import frontendMIcon from './assets/icon-frontend.svg';
import emailIcon from './assets/icon-email.svg';
import { BrowserRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {

    function openEmail(){
        window.open('mailto:irinebthayil@gmail.com')
    }

    return (
        <BrowserRouter>
            <footer className={classes['footer-parent-div']}>
                <div className={classes['hr-div']} />
                <div className={classes['footer-main-div']}>
                    <div className={classes['footer-info-div']}>

                        <div className={classes['logo-div']}>
                            <HashLink smooth to='#Home'><img id={classes['footerLogo']} src={logo} title='Irine B Thayil' alt='Irine B Thayil' /></HashLink>
                        </div>

                        <div className={classes['footer-icons-div']}>
                            <a href='https://github.com/irinebthayil' target='_blank'><img src={githubIcon} className={classes['header-icons']} title="Irine's Github" alt="Irine's Github" /></a>
                            <a href='https://www.linkedin.com/in/irinebthayil/' target='_blank'><img src={linkedinIcon} className={classes['header-icons']} title="Irine's LinkedIn" alt="Irine's LinkedIn" /></a>
                            <a href='https://www.frontendmentor.io/profile/irinebthayil' target='_blank'><img src={frontendMIcon} className={classes['header-icons']} title="Irine's Frontend Mentor" alt="Irine's Frontend Mentor" /></a>
                        </div>

                        <div className={classes['email-div']} onClick={openEmail}>
                            <img src={emailIcon} title='Email Me' alt='Email Me' />
                            <span>irinebthayil@gmail.com</span>
                        </div>
                    </div>

                    <div className={classes['footer-nav-div']}>
                        <HashLink smooth to='#Home' className={classes['footer-navlink']}>Home</HashLink>
                        <HashLink smooth to='#Projects' className={classes['footer-navlink']}>Projects</HashLink>
                        <HashLink smooth to='#Contact' className={classes['footer-navlink']}>Contact</HashLink>
                    </div>
                </div>
            </footer>
        </BrowserRouter>

    )
}

export default Footer