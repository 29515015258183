import styled from 'styled-components';


export const PrimaryButton = styled.button`
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    background-color: var(--color-black);
    color: var(--color-secondary);
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: ${props => (props.margintop ? props.margintop : '0px')};

    &:hover:not([disabled]) {
        background-color: var(--color-secondary);
        color: var(--color-black);
    }

    &:disabled {
        cursor: default;
        opacity: 0.6;
        
    }
`

export const PrimaryButton2 = styled.button`
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    background-color: var(--color-black);
    border: 1px solid var(--color-black);
    color: var(--color-secondary);
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: ${props => (props.margintop ? props.margintop : '0px')};

    &:hover {
        background-color: var(--color-white);
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
    }
`

export const SecondaryButton = styled.button`
    outline: none;
    border: none;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: 500;
    background-color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
    color: var(--color-black);
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: ${props => (props.margintop ? props.margintop : '0px')};


    &:hover {
        background-color: var(--color-white);
        border: 1px solid var(--color-secondary);
        box-sizing: border-box;
    }
`

